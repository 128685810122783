import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/smooth-doc/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "loadablewebpack-plugin"
    }}><a parentName="h1" {...{
        "href": "#loadablewebpack-plugin",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`@loadable/webpack-plugin`}</h1>
    <h3 {...{
      "id": "loadableplugin"
    }}><a parentName="h3" {...{
        "href": "#loadableplugin",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`LoadablePlugin`}</h3>
    <p>{`Create a webpack loadable plugin.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional options`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.filename`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stats filename (default to `}<inlineCode parentName="td">{`loadable-stats.json`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.outputAsset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Always write stats file to the `}<inlineCode parentName="td">{`output.path`}</inlineCode>{` directory. Defaults to `}<inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.writeToDisk`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accepts `}<inlineCode parentName="td">{`boolean`}</inlineCode>{` or `}<inlineCode parentName="td">{`object`}</inlineCode>{`. Always write stats file to disk. Default to `}<inlineCode parentName="td">{`false`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`options.writeToDisk.filename`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Write assets to disk at given `}<inlineCode parentName="td">{`filename`}</inlineCode>{` location`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`new LoadablePlugin({ filename: 'stats.json', writeToDisk: true })
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Writing file to disk can be useful if you are using `}<inlineCode parentName="p">{`razzle`}</inlineCode>{` or `}<inlineCode parentName="p">{`webpack-dev-server`}</inlineCode>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      